#settings{
	.filters{
		.actions, .search{
			box-shadow: 0 2px 4px 0 rgba(57,57,57,0.21);
			.btn-filter{
				width: 100%;
			}
		}
	}
	table{
		tbody{
			tr{
				th{
					padding: 12px 40px 12px 16px;
				}
			}
		}
	}
}

.cntAuthentication{
	margin-top: 30px;
   .authCntSeparate{
		margin-top: 34;
		background: whitesmoke;
		display: flex;
        flex-direction: row;
		.selector{
			background:#036ee7; 
			min-width: 10px;
			height: 100%;
		}
		.selected{
			background:#036ee7; 
		}
		.no-selected{
			background:whitesmoke; 
		}
		.subContainer{
			margin-left: 10px;
			padding: 0px 40px 20px;
			h4{
			   font-weight: 400
			}
			.bh4{
				font-weight: 500;
				margin-top: 30px;
				margin-bottom: 8px;
			}
			.cntRadioButton{
				position: relative;
				p{
					font-size: 15px;
				}
				.radio{
					position: absolute;
                    left: -50px;
                    top: -9px;
				}
			}
			.cntDomains{
				margin-top: 5px;
				.subCntDomains{
					div{
						margin-top: 3px;
						padding-left: 4px;
						label{
							font-size: 12px;
							color: #666
						}
					}
				}
			}
			.guiaCaption{
				margin-bottom: 20px;
				padding-left: 12px;
				a{
					color:#2188FC;
				}
			}
			.cntButton{
				margin-top: 30px;
				.btn{
					padding-left: 40px;
					padding-right: 40px;
				}
			}
			.subCntTexfield{
				width: 70%;
                background: #fff;
                border: 1px solid silver;
			}
			.inputTitle{
				margin-top: 15px;
			}
		}
   }
}
.ctnAuthTitle h5{
	margin-top: 20px; 
	font-weight: 500;
}

#statistics{
	.point{
		background: #F5A623;
		border: 3px solid rgba(255,255,255,0.7);
		height: 16px;
		width: 16px;
		border-radius: 50%;
	}
	.tool-bar{
		height: 100%;
		min-height: initial;
		padding-left: 0;
        padding-right: 0; 
		flex-wrap: wrap;
		.remove-underline{
			&::before,&.MuiInput-underline:after{
				content: none;
			}
		}
		.calendarInput-border .MuiInputBase-root{
			border: 1px solid #E8E8E8;
			padding-left: 6px;
		}
		.borderInputs{
			border: 1px solid #E8E8E8;
			padding-left: 6px;
			padding-right: 7px;
		}
		.colorsquare{
			padding-left: 10px;
			&::before{
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 6px;
				bottom: 0;
			}
			&.yellow::before{
				background:	#F5A623;
			}
			&.green::before{
				background:	#72BD00;
			}
			&.blue::before{
				background:	#00C0FF;
			}
			&.pink::before{
				background:	#B14997;
			}
		}
		label + .MuiInput-formControl{
			margin-top: 20px;
		}
        .btn-search{
        	background: $color--primary;
        	height: 34px;
        	width: 34px;
        	flex-wrap: wrap;
        	align-content: center;
        	color: #fff;
        }
        .date-picker{
        	margin-right: 5px; 
		    background: #fff;
		    margin-top: 0;
			border: none;
			border: 1px solid #E8E8E8;
			border-radius: 0px;
			padding-left: 6px;
		    font-size: 14px;
			color: #121212;
		    & > div{
                height: 100%;
                &::before,&.MuiInput-underline:after{
                	content: none;
                }
		    }
		    input{
		    	font-size: 14px;
		    }
		}
		.ant-picker-active-bar{
			background: transparent !important;
		}
		.disableFilter{
			background-color: #e8e8e8;
		}
	}
	.preriods{
		.period{
			min-width: 285px;
		}
		.info{
			line-height: 1.3;
		}
	}
	.general-datas{
		.card-data{
			padding-left: 18px;
			height: 100%;
			cursor: pointer;
			@include cp-property(transition, 400ms ease-out);
			.content{
                height: 100%;
                padding-bottom: 15px;
                .MuiTypography-root{
                	height: 100%;
                	display: flex;
                	flex-wrap: wrap;
                }
			}
			.point{
				position: absolute;
		        right: 5px;
		        top: 0;
			}
			.icon{
				position: absolute;
				right: 100%;
				top: 15px;
			}
			[category]{
				color: $color--textSecundary;
			}
			&:hover{
                box-shadow: 0 4px 4px 0 rgba(0,0,0,0.16);
			}
		}
	}
	.graphic{
		border-color: #e8e8e8;
		&.content-doughnut{
			min-height: 325px;
		}
		.point{
			height: 19px;
			width: 19px;
			border: 4px solid rgba(255,255,255,0.7);
		}
		.title{
			.text{
				position: relative;
				.point{
					@extend %vertical-align;
					left: 0;
				}
			}	
		}
		.horizontal-bar, .line{
			canvas{
				max-width: 100%;
			}
		}
		.bar{
			canvas{
			    max-width: 100%;
			}
		}
		.doughnut{
			canvas{
				max-width: 280px;
				max-height: 280px;
			}
		}
	}

	.requested-tutorials{
		.content-graphic{
			.matter{
				&:not(:last-child){
					border-bottom: 1px dashed #dedede
				}
				.text{
					white-space: nowrap;
					overflow: hidden;
                    text-overflow: ellipsis;
				}
				.color{
					@extend %vertical-align;
					left: -16px;
					height: 16px;
					width: 11px;
				}
			}
		}
	}

	.requested-tutorials{
		.point{
			@extend %vertical-align;
			left: 0;
			height: 16px;
			width: 16px;
		}
	}
}
.chipSpan{
    display: block;
    border-radius: 4px;
    padding: 2px;
    color: white;
    width: 92px;
    text-align: center;
}
.customChipSpan{
    display: inline-block;
    border-radius: 4px;
    padding: 2px;
    color: white;
    width: 92px;
    text-align: center;
    font-size: 14px;
}
.sChipSpan{
    display: inline;
    border-radius: 4px;
    padding: 4px;
    color: white;
    width: 92px;
    text-align: center;
    font-size: 14px;
}
.individual{
    background-color:#2288fc;
}
.group{
    background-color:rgb(245, 166, 35);
}

@keyframes bounceIn{
    0%{
        opacity: 0;
        transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.1);
    }
    80%{
        transform: scale(0.9);
    }
    100%{
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}
@-webkit-keyframes bounceIn{
    0%{
        opacity: 0;
        -webkit-transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        -webkit-transform: scale(1.1);
    }
    80%{
        -webkit-transform: scale(0.9);
    }
    100%{
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
    }
}
@-o-keyframes bounceIn{
    0%{
        opacity: 0;
        -o-transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        -o-transform: scale(1.1);
    }
    80%{
        -o-transform: scale(0.9);
    }
    100%{
        opacity: 1;
        visibility: visible;
        -o-transform: scale(1);
    }
}
@-moz-keyframes bounceIn{
    0%{
        opacity: 0;
        -moz-transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        -moz-transform: scale(1.1);
    }
    80%{
        -moz-transform: scale(0.9);
    }
    100%{
        opacity: 1;
        visibility: visible;
        -moz-transform: scale(1);
    }
}
@-ms-keyframes bounceIn{
    0%{
        opacity: 0;
        -ms-transform: scale(0.3);
    }
    50%{
        opacity: 0.9;
        -ms-transform: scale(1.1);
    }
    80%{
        -ms-transform: scale(0.9);
    }
    100%{
        opacity: 1;
        visibility: visible;
        -ms-transform: scale(1);
    }
}
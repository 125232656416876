#promotion{
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgba(57,57,57,0.21);
	min-height: 650px;

    .modal-download{
    	opacity: 0;
    	pointer-events: none;
    	@include cp-property(transition, 400ms);
    	.colorBlur{
    		cursor: pointer;
    	}
    	.content-modal{
    		@include cp-property(transition, 400ms);
    		@include cp-property(transform, translateY(20px));
    		.title{
    			position: relative;
    			.btn-close{
    			    @extend %vertical-align;
    			    right: 25px;
    			    cursor: pointer;
    		    }
    		}
    		.icon-status{
    			display: block;
    			visibility: hidden;
    		}
    		.btn-acept{
    			width: 100%;
    			max-width: 240px;
    		}
    	}
    	&.show{
    		opacity: 1;
    		pointer-events: all;
    		.content-modal{
    			@include cp-property(transform, translateY(0));
    			.icon-status{
                    @include cp-property(animation, bounceIn 400ms 400ms forwards ease-out);
    			}
    		}
    	}
    }

	.btn-promotion{
		border: 2px solid #E9E9E9;
		padding: 30px 20px 20px 20px;
		text-align: center;
		border-radius: 4px;
		background: transparent;
		cursor: pointer;
		@include cp-property(transition, 400ms);
		overflow: hidden;
		position: relative;
		&.no-hover{
			cursor: initial;
		}
        &:not(.no-hover):hover{
        	box-shadow: -5px 7px 11px 0 rgba(0,0,0,0.13);
        }
        .hover{
        	position: absolute;
        	left: 0;
        	top: 0;
        	right: 0;
        	bottom: 0;
        	opacity: 0;
        	cursor: pointer;
        	@include cp-property(transition, 400ms);
        	.hover-content{
        		position: absolute;
        		@include cp-property(transform, translate(-50%,-40%));
        		left: 50%;
        		top: 50%;
        		@include cp-property(transition, 400ms);
        	}
        	&:hover{
        		opacity: 1;
        		.hover-content{
        			@include cp-property(transform, translate(-50%,-50%));
        		}
        	}
        }
    }

    .segment{
    	box-shadow: -5px 7px 11px 0 rgba(0,0,0,0.13);
    }

    .btn-back{
    	border: none;
    	background: transparent;
    	padding-left: 25px;
    	cursor: pointer;
    	position: relative;
    	i{
    		@extend %vertical-align;
    		@include cp-property(transition, 400ms);
    		left: 0;
    	}
    	&:hover{
    		i{
    			left: -6px;
    		}
    	}
    }

    .btn-downloads{
    	background: #D8D8D8;
    	border-radius: 0;
    	width: 100%;
    	height: 30px;
    	padding: 0 5px;
    	.text{
    		position: relative;
    		padding-left: 26px;
    		font-weight: 400;
    		i{
    			@extend %vertical-align;
    			left: 0;
    		}
    	}
    }

    .btn-view{
    	padding: 0;
    	border: none;
    	background: transparent;
    	padding-right: 20px;
        cursor: pointer;
    	position: relative;
    	i{
            @extend %vertical-align;
            right: 0;
    	}
        .preview{
            position: absolute;
            right: 0;
            bottom: 32px;
            box-shadow: 0 2px 4px 0 rgba(57,57,57,0.21);
            background: white;
            visibility: hidden;
            pointer-events: none;
            img{
                margin: 15px;
            }
            &::after{
                content: '';
                position: absolute;
                top: 100%;
                right: 10px;
                border: 12px solid #121212;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid transparent;
                opacity: 0.2;
            }
        }
        &:hover{
            .preview{
                visibility: hidden;
                pointer-events: all;
                @include cp-property(animation, bounceIn 400ms 400ms forwards ease-out);
            }
        }
    }

    .btn-downloadAll{
    	width: 100%;
    	max-width: 250px;
    }
    .downloads{
    	&:nth-child(2){
    		img{
    			max-height: 65px;
    		}
    	}
    }
}
@media only screen and (min-width: 960px){


    // ==================== Menu compacto ========================
    .box-menu{
        .menu {
            .content-img.compact{
                @include cp-property(transition, 400ms linear);
                @include cp-property(transition-delay, 200ms);
                @include cp-property(transform, translateX(-100%));
                img{
                    opacity: 0;
                    @include cp-property(transition, 100ms linear);
                    @include cp-property(transition-delay, 0);
                }
            }
            .nav-menu.compact{
                .content-btn{
                    &:hover{
                        > .btn-menu{
                            background-color: rgba(0,0,0,0.08);
                        }
                        .sub-menu{
                            opacity: 1;
                            pointer-events: all;
                            .btn-menu{
                                &:first-child{
                                    background-color: rgba(0,0,0,0.08);
                                }
                                &::after{
                                    width: 0px;
                                    @include cp-property(transition, 0ms linear);
                                }
                            }
                        }
                    }
                }
                .btn-menu{
                    .text,.chevron{
                        opacity: 0;
                        pointer-events: none;
                        min-width: 182px;
                        @include cp-property(transition, 100ms);
                    }
                }
                .sub-menu{
                    position: absolute;
                    left: 100%;
                    top: 0;
                    width: 240px;
                    border-radius: 0 5px 5px 0;
                    pointer-events: none;
                    opacity: 0;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border: 1px solid rgb(236,236,236);
                        border-left: transparent;
                        border-radius: 0 5px 5px 0;
                        pointer-events: none;
                    }
                }  
            }
        }
        &[open]{
            @include cp-property(transform, translateX(0%));
        }
    }
    // ==================== Menu compacto ========================

}


@media only screen and (max-width: 959px){

    .box-menu{
    	@include cp-property(transform, translateX(-100%));
        width: 240px !important;
        z-index: 2100;
    	.menu {
    		z-index: 200;
            .btn-statusMenu{
            	display: none;
            }
            .nav-menu{
                @include cp-style(height, calc, unquote("(100vh - 61px)"));
                overflow-y: scroll;
            }
            .nav-menu.compact .sub-menu .btn-menu:first-child{
                display: none;
            }
            .content-img{
                &::after{
                    height: 0px;
                }
            }
    	}
    	&[open]{
    		@include cp-property(transform, translateX(0%));
    	}

    }
    .blurcolor{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.5);
        z-index: 2000;
        pointer-events: none;
        opacity: 0;
        cursor: pointer;
        @include cp-property(transition, 400ms linear);
        &[open]{
        	pointer-events: all;
        	opacity: 1;
        }
    }

}
$color--primary: #2188FC;
$color--text: #121212;
$color--textSecundary: #B0B0B0;
/* ================ Mixins (include) ================== */
@mixin cp-property($property,$style){
    #{$property} : $style;
    -webkit-#{$property} : $style;
    -moz-#{$property} : $style;
    -o-#{$property} : $style;
    -sm-#{$property} : $style;
}

@mixin cp-style($property,$style,$val){
    #{$property} : $style#{$val};
    #{$property} : -webkit-$style#{$val};
    #{$property} : -moz-$style#{$val};
    #{$property} : -o-$style#{$val};
    #{$property} : -sm-$style#{$val};
}
/* ================ Mixins (include) ================== */
/* ================ Herencias (extend) ================== */
%center-align{
	position: absolute;
	@include cp-property(transform,translateX(-50%) translateY(-50%));
	left: 50%;
	top: 50%;
}

%vertical-align{
	position: absolute;
	@include cp-property(transform, translateY(-50%));
	top: 50%;
}

%horizontal-align{
	position: absolute;
	@include cp-property(transform, translateX(-50%));
	left: 50%;
}
/* ================ Herencias (extend) ================== */
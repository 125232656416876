.guest{
  border-radius: 4px;
  padding: 1px 4px;
  color: white;
  font-size: 10px;
  background-color: #3498db;
  margin-left: 5px;
}
#cntLabel{
	position: relative;
	.label{
	  position: absolute;
    top: -13px;
    right: 0;
  }
  .span{
    border-radius: 4px;
    padding: 5px 10px;
    color: white;
    font-weight: 700;
    font-size: 10px;
    background-color: #333;
    margin-left: 5px;
  }
}

#news{
	.filters{
		.actions, .search{
			box-shadow: 0 2px 4px 0 rgba(57,57,57,0.21);
			.btn-filter{
				width: 100%;
			}
		}
	}
	.zeroPaddingTableButton{
		padding: 0;
		margin-right: 10px;
	}
	table{
		thead,tbody{
			tr{
				th:first-child{
					padding-left: 110px;
				}
			}
		}
		tbody{
			tr{
				th{
					.text{
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
					.point{
						@extend %vertical-align;
						&:before{
                            content: '';
                            @extend %vertical-align;
                            left: -26px;
                            width: 16px;
                            height: 16px;
                            background-color: #72BD00;
                            box-shadow: 0 0 0 4px rgba(114,189,0,0.22);
                            border-radius: 50%;
						}
					}
				}
			}
		}
	}
}
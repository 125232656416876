header{
	&::before{
		content: none !important;
	}
}

.main-title{
	margin-bottom: 15px !important;
}

.box-menu{
	z-index: 500;
}

.app{
	z-index: 200;
}

.app{
	//@include cp-property(transition, 400ms linear);
}

.most-info{
	.info{
		display: none;
		height: 15px;
		width: 15px;
		border-radius: 50%;
		background: $color--primary;
	}
}

.box-menu{
	z-index: 1150;
	background: #fff;
	@include cp-property(transition, 400ms linear);
}

.external-link{
	color: $color--text;
	&:focus,&:hover{
		color: $color--primary;
		text-decoration: underline;
	}
}

.input-tolerance{
	padding-top: 27px;
	padding-left: 5px;
	padding-right: 5px;
	height: 100%;
	.label{
        font-size: 12px;
        width: 100%;
	}
	.number{
		margin-right: 5px;
		margin-top: 4px;
		p.Mui-error{
			position: absolute;
			top: 100%;
			left: 0;
			width: 250px;
			background: #fff;
			margin-top: 0;
			padding-top: 5px;
		}
	}
	.tolerance,.type-num{
		width: 100%;
        margin-right: 5px;
        margin-top: 4px;
	}
	.span-content{
		min-width: 140px;
        display: flex;
        align-items: center;
	}
}

.menu{
	position: relative;
	.content-img{
		position: relative;
		padding-top: 12px;
		padding-bottom: 12px;
		left: 0;
		height: 100%;
		width: 100%;
		// ===== animación para el menu al precionar el boton
		@include cp-property(transition, 200ms linear);
		img{
			height: 100%;
			max-width: 100%;
			@include cp-property(transition, 400ms linear);
			@include cp-property(transition-delay, 400ms);
		}
		&::after{
		    content: '';
		    @extend %vertical-align;
		    left: 100%;
		    height: 60%;
		    width: 1px;
		    background-color: rgba(0,0,0,0.31);
		    @include cp-property(transition, 200ms);
		    z-index: 50;
	    }
	    &.compact{
	    	&::after{
	    		height: 0;
	    	}
	    }
	    // animación para el menu al precionar el boton ======
	}
	.btn-statusMenu{
		position: absolute;
		left: 100%;
		top: 0;
		z-index: 20;
		height: 100%;
		width: 50px;
		border-radius: 0;
		i{
			font-size: 27px;
		}
	}
	.nav-menu{
		@include cp-style(min-height, calc, unquote("(100vh - 61px)"));
		.btn-menu{
			.icon{
				min-width: 35px;
				font-size: 16px;
				padding-right: 15px;
				display: flex;
				justify-content: center;
			}
			&::after{
				content: '';
				@extend %vertical-align;
				left: 0;
				width: 0px;
				height: 100%;
				@include cp-property(transition, 400ms);
			}
			&:hover,&.btn-active{
			    &::after{
				    width: 5px;
			    }
			}
			.btn-text {
				span{
				    font-size: 15px;
				    font-weight: 300;
			    }
			    &.text-bold{
					span{
						font-weight: 500;
					}
				}
			}
			.text,.chevron{
                @include cp-property(transition, 400ms linear);
			    @include cp-property(transition-delay, 400ms);
			}
			.chevron{
				font-size: 18px;
				@extend %vertical-align;
				right: 15px;
			}
			&:focus,&:hover{
				color: inherit;
			}
			
		}
		.sub-menu{
            background: #fff;
		}
		&:not(.compact){
			.sub-menu{
				position: relative;
				&::before{
					content: '';
					@extend %vertical-align;
					left: 22px;
					width: 2px;
					height: 98%;
					opacity: 0.6;
					background-color: #d8d8d8;
				}
				.btn-menu{
					padding-left: 38px;
					&::before{
						content: '';
						@extend %vertical-align;
						left: 20.5px;
						width: 4px;
						height: 4px;
						background-color: darken(#d8d8d8, 4%);
						border-radius: 4px;
					}
				}
			}
		}
	}	
}

.modal-form{
	opacity: 0;
	pointer-events: none;
	@include cp-property(transition, 400ms);
	&.show{
		opacity: 1;
		pointer-events: all;
	}
	.content-modal{
		overflow: hidden;
		#content-form{
			overflow-y: scroll;
			@include cp-style(height, calc, unquote('( 100% - 108px)'));
		}
	}
	.form-title{
		h3{
			line-height: 100%;
		}
	}
    .input-form{
    	margin-bottom: 30px;
    	margin-top: 0;
    	width: 100%;
    }
    .ReactCrop{
    	width: 100%;
    }
    .input-file{
    	.title{
    		white-space: nowrap;
    		text-overflow: ellipsis;
    		overflow: hidden;
    	}
    	.subtitle{
    		/* autoprefixer: off */
    		-webkit-box-orient: vertical;
    		/* autoprefixer: on */
    		display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
    	}
    	&.input-error{
    		border: 2px solid #f44336;
    	}
    	input{
    		position: absolute;
    		opacity: 0;
    		pointer-events: none;
    	}
    }
    .img-drop{
    	@extend %vertical-align;
    	left: 110%;
    	width: 100%;
    }
    .btn-load, .btn-remove{
    	width: 100%;
    }
    .color-picket{
    	position: relative;
    	.btn-color{
    		cursor: pointer;
    		border-radius: 4px 0 0 4px;
    		i{
    			@extend %center-align;
    			font-size: 18px;
    			color: #fff;
    		}
    	}
    	.input-color{
    		input{
    			padding-left: 15px;
    		}
    	}
    	.colorpicket{
    		position: absolute;
    		bottom: 45px;
    		left: 0;
    	}
    }
    .btn-image{
    	
    	button{
    		background: #fff;
    	    border: none;
    	    height: 30px;
    	    font-size: 14px;
    	    font-weight: 500;
    	    width: 140px;
    	    border-radius: 4px;
    	    cursor: pointer;
    	    &:focus{
    		    outline: none;
    	    }
    	}
    	&:link{
    		text-decoration: none;
    	}
    	
    }
    .btn-add{
    	width: 100%;
    	max-width: 320px;
    }
    .btn-close{
    	@extend %vertical-align;
    	cursor: pointer;
    	right: 0;
    }
}

.custom-icon-in-person {
	font-size: 13px;
    vertical-align: text-top;
}

.custom-icon-online {
	font-size: 12px;
    vertical-align: text-top;
}

.popec{
	&::after{
		content: attr(datatemp);
		position: absolute;
		bottom: 0;
		left: 0;
		padding-bottom: 10px;
		width: 50%;
		padding-left: 15px;
		padding-right: 15px;
		color: red;
		font-size: 12px;
	}
	.PrivatePickersSlideTransition-root{
        margin-bottom: 10px;
	}
}

.birthdayDatePicker .MuiFormControl-root{
	margin-top: 27px; 
	width: 100%;
}